<template>
  <div class="homepage-5 the-search hd-white">
    <div class="row justify-content-md-center">
      <div class="col-md-6 text-center">
        <img
          style="width: 40%"
          :src="require('../assets/images/welcomepage.png')"
        />
      </div>
     
    </div>
    <div class="row justify-content-md-center">
       <div class="col-md-6 text-center">
        <h6
          class="text-center"
          style="font-family: 'Montserrat', sans-serif; font-weight: 600"
        >
          Thank you for signing up and welcome to vencasa.
        </h6>
        <h2
          class="text-center"
          style="font-family: 'Montserrat', sans-serif; font-weight: 700; font-size:45px;"
        >
          Press list property to get started selling
        </h2>
<div class="mt-3">
         <button @click="$router.push('/')"
         
                          class="res-button-prop" 
                          style="
                            background-color: #548f4d;
                            color: white;
                            padding: 20px 86px;

                            border-radius: 8px;
                           
                            border-color: rgb(84, 143, 77) !important;
                            border: 0px;
                            margin-right: 20px;
                            font-family: 'Montserrat', sans-serif;
                          "
                        >
                          Search
                        </button>
                        <button
                          class="res-button-prope"
                         @click="$router.push('/add-property')"
                          style="
                            background-color: #548f4d;
                            color: white;
                            padding-top: 20px;
                            padding-left: 60px;
                            padding-bottom: 20px;
                            padding-right: 60px;
                            border-radius: 8px;
                         
                            border-color: rgb(84, 143, 77) !important;
                            border: 0px;
                            font-family: 'Montserrat', sans-serif;
                          "
                        >
                          List Property
                        </button>
                        </div>
      </div>
      </div>
    <div id="wrapper">
      <section class="mt-4">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-6 pr-1 pt-1"></div>
          </div>
        </div>
      </section>
      <section
        v-lazyload
        :data-url="require('../assets/images/bg/home_section.webp')"
        class="mt-4 info-help h18 pedrem"
      >
        <div class="container">
          <div class="row justify-content-md-center info-head">
            <div class="col-lg-12 col-md-8 col-xs-8">
              <div class="info-text d-flex justify-content-center">
                <div class="pl-3 pr-3">
                  <video
                  class="res-video"
                    id="video"
                    :src="require('../assets/images/Vencasa-Short-Ad-4K.mp4')"

                   
                    :poster="require('../assets/images/vencasa-snap.png')"
                    webkit-playsinline
                    loop
                    controls
                    playsinline
                    
                  ></video>
                  <div class="play-button-wrapper">
                    <div
                      title="Play video"
                      class="play-gif"
                      id="circle-play-b"
                      style="opacity: 1"
                    >
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container mt-5 res-show-mobile">
        <h6
          class="text-center"
          style="font-family: 'Montserrat', sans-serif; font-weight: 600"
        >
          VENCASA EXPLAINED
        </h6>
        <h2
          class="text-center"
          style="font-family: 'Montserrat', sans-serif; font-weight: 600"
        >
          How it Works
        </h2>
        <div class="row mt-5">
          <div class="col-sm text-center">
            <strong
              @click="$router.push('/add-property')"
              style="
                text-transform: initial;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.5rem;
              "
              class="mb-5"
              >List your property</strong
            >

            <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
              Tell us about your property/ take pictures, write a description
              and create a floor plan.
            </p>
          </div>
          <div class="col-sm text-center">
            <strong
              style="
                text-transform: initial;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.5rem;
              "
              class="mb-5"
              >Make payment</strong
            >

            <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
              One simple payment, no hidden fees or percentage commissions. We
              also plant a tree on your behalf.
            </p>
          </div>
          <div class="col-sm text-center">
            <strong
              style="
                text-transform: initial;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.5rem;
              "
              class="mb-5"
              >Show off your property</strong
            >

            <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
              Share your new listing with friends on social media. Message
              buyers directly and arrange viewings.
            </p>
          </div>
          <div class="col-sm text-center">
            <strong
              style="
                text-transform: initial;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.5rem;
              "
              class="mb-5"
              >Accept an Offer</strong
            >

            <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
              Accept an offer you are happy with and your solicitors will handle
              all the legal side of things.
            </p>
          </div>
          <div class="col-sm text-center">
            <strong
              style="
                text-transform: initial;
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.5rem;
              "
              class="mb-5"
              >Relax</strong
            >

            <p
              style="
                text-transform: initial;
                margin-left: 2px;
                margin-right: 4px;
                padding-right: 10px;
                font-size: 1rem;
                padding-left: 10px;
                font-family: Montserrat, sans-serif;
              "
              class="mt-4"
            >
              Congratulations, now sit back and ponder the ways to treat
              yourself with your saved commission.
            </p>
          </div>
        </div>
      </div>
      <section
        id="how-it-works"
        style="margin-top: 90px; margin-left: 25px; margin-right: 25px"
        _ngcontent-bgi-c3=""
        class="featured-boxes-area res-s-dnone"
      >
        <div id="how" class="title container mt-5">
          <h6
            class="text-center"
            style="font-family: 'Montserrat', sans-serif; font-weight: 600"
          >
            VENCASA EXPLAINED
          </h6>
          <h2
            class="text-center"
            style="font-family: 'Montserrat', sans-serif; font-weight: 600"
          >
            How it Works
          </h2>
        </div>

        <div style="margin: 2em auto; width: 80%" class="row">
          <ul
            style="margin-left: -60px; margin-right: -60px"
            class="progress-steps"
          >
            <li class="active">
              <strong
                @click="$router.push('/add-property')"
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5"
                >List your property</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Tell us about your property/ take pictures, write a description
                and create a floor plan.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5"
                >Make payment</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                One simple payment, no hidden fees or percentage commissions. We
                also plant a tree on your behalf.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5"
                >Show off your property</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Share your new listing with friends on social media. Message
                buyers directly and arrange viewings.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5"
                >Accept an Offer</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Accept an offer you are happy with and your solicitors will
                handle all the legal side of things.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5"
                >Relax</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Congratulations, now sit back and ponder the ways to treat
                yourself with your saved commission.
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section
        class="blog-section bg-white"
        :style="`background-image : url(${require('../assets/images/bg/big.webp')}) !important; background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
    padding: 0px;
    background-repeat: no-repeat !important;`"
      >
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-auto mt-5">
              <h6
                class="text-uppercase text-center mb-4"
                style="font-family: 'Montserrat', sans-serif; font-weight: 600"
              >
                Our Pricing
              </h6>
              <h3
                class="text-center mb-5"
                style="font-size: 30px; font-family: 'Montserrat', sans-serif"
              >
                One Plan, Fixed Pricing
              </h3>
              <div
                class="text-center"
                style="
                  border: 3px solid;
                  margin-bottom: 20px;
                  border-color: rgb(84, 143, 77);
                  border-radius: 8px;
                "
              >
                <div class="p-3">
                  <h5
                    class="mb-3"
                    style="
                      font-weight: 700;
                      font-size: 25px;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    The One
                  </h5>
                  <h5
                    class="mb-3"
                    style="
                      font-size: 45px;
                      color: #548f4d;
                      font-weight: 700;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    £250
                  </h5>
                  <h6
                    class="mb-5"
                    style="
                      font-weight: bold;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    WHAT'S INCLUDED
                  </h6>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Direct Contact with Potential Buyer
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    List Until Sold
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Offers Sent Instantly
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Unlimited viewings at Times to Suit You
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      margin-bottom: 30px;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    You Keep Full Control
                  </p>
                  <button
                    @click="$router.push('/add-property')"
                    class="res-button-how"
                    style="
                      background-color: rgb(84, 143, 77);
                      color: white;
                      padding: 20px 60px;
                      border-radius: 5px;
                      border: 0px;

                      margin-bottom: 10px;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    List Your Property
                  </button>
                </div>
              </div>
              <div class="text-center">
                <span style="font-family: 'Montserrat', sans-serif">
                  Are you a property developer?
                </span>
                <router-link
                  style="font-family: 'Montserrat', sans-serif"
                  to="/contact-us"
                >
                  Contact us
                </router-link>
              </div>
              <br />
            </div>
          </div>
          
        </div>
      </section>
      <a data-scroll href="#wrapper" class="go-up" style="display: inline"
        ><i class="fa fa-angle-double-up" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      hasClicked: false,
      usernameError: false,
      emailError: false,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    username: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  watch: {
    email() {
      if (this.hasClicked) this.emailError = false;
    },
    username() {
      if (this.hasClicked) this.usernameError = false;
    },
  },
  computed: {
    ...mapGetters({
      check: "check",
    }),
  },
  methods: {
    async login() {
      await this.$store.dispatch("getUser").then((res) => {
        this.$store.commit("setShowLoader", false);
        if (res !== true) {
          this.$router.push("/login");
        }
      });
    },
    async register() {
      if (this.$v.$invalid) {
        if (this.$v.firstName.$invalid) this.$v.firstName.$touch();
        if (this.$v.lastName.$invalid) this.$v.lastName.$touch();
        if (this.$v.username.$invalid) this.$v.username.$touch();
        if (this.$v.email.$invalid) this.$v.email.$touch();
        if (this.$v.password.$invalid) this.$v.password.$touch();
        if (this.$v.passwordConfirmation.$invalid)
          this.$v.passwordConfirmation.$touch();
      } else {
        this.hasClicked = true;
        this.$store.commit("setShowLoader", true);
        const data = {
          name: this.firstName + " " + this.lastName,
          email: this.email,
          password: this.password,
          user_name: this.username,
          password_confirmation: this.passwordConfirmation,
        };
        await this.$store.dispatch("register", data).then((res) => {
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            if (this.ref_code) {
              this.$store
                .dispatch("sendRequestIfCookie", this.ref_code)
                .then((res) => {
                  if (res == true) {
                    this.$router.push("/email-verification");
                  }
                });
            } else this.$router.push("/email-verification");
          } else if (res.data.code == 422 && res.data.errors.email) {
            this.emailError = true;
            document
              .querySelector(".email")
              .scrollIntoView({ behavior: "smooth" });
          } else if (res.data.code == 422 && res.data.errors.user_name) {
            this.usernameError = true;
            document
              .querySelector(".username")
              .scrollIntoView({ behavior: "smooth" });
          } else if (res.data.code == 422 && res.data.errors.password) {
            alert("The passwords must match");
          }
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
const video = document.getElementById("video");
      const circlePlayButton = document.getElementById("circle-play-b");

      function togglePlay() {
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }

      circlePlayButton.addEventListener("click", togglePlay);
      video.addEventListener("playing", function () {
        circlePlayButton.style.opacity = 0;
      });
      video.addEventListener("pause", function () {
        circlePlayButton.style.opacity = 1;
      });
    });
    if (!this.check) {
      this.$store.commit("setShowLoader", true);
      this.accessToken = this.$router.currentRoute.query.access_token;
      if (_.size(this.accessToken)) {
        localStorage.setItem("buyer_access_token", this.accessToken);
        this.login();
      } else {
        this.$store.commit("setShowLoader", false);
      }
    }
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }

  .res-show-mobile {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .res-butt {
    display: none !important;
  }
  .res-p {
    padding-top: 187px;
  }
   .res-video{
    height:200px !important;
    width:100% !important;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-login {
    width: 100% !important;
    margin: 0px !important;
  }

  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }

  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 265px !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    width: 250px !important;
    padding: 13px !important;
    margin: 0px !important;
  }

  .res-button-api {
    width: 320px !important;
    padding: 12px !important;
    margin: 13px !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;
    margin-right: 0px;
    width: 290px !important;
    max-width: 290px !important;
    margin-bottom: 10px !important;
  }
  div >>> .multiselect__select {
    left: 76% !important;
  }

  div >>> .multiselect {
    padding-left: 10px !important;
  }

  .res-postal-code {
    width: 289px !important;
    margin-left: 24px !important;
    padding-left: 32px !important;
  }
  .res-threedot {
    margin-left: 19px !important;
    width: 89.9% !important;
  }

  .res-margin-save-set {
    margin-top: 15% !important;
  }

  .sec-title {
    padding-bottom: 0px !important;
  }

  .res-mt-2 {
    margin-top: 40px !important;
  }

  .admin {
    display: none !important;
  }

  .res-threedot span {
    padding-right: 24px !important;
  }

  .res-bed-icon {
    position: absolute;
    left: 12.5% !important;
    z-index: 9999;
    top: 112px;
    top: 66% !important;
  }

  .res-bathroom-icon {
    left: 10% !important;
  }

  .res-garden-icon {
    left: 10% !important;
  }

  .res-parking-icon {
    left: 10% !important;
  }

  .res-swmin-icon {
    left: 10% !important;
  }

  .res-pl-0 {
    padding-left: 0px !important;
  }

  .res-button-prop {
    padding: 15px 35px !important;
  }
  .res-button-prope {
    padding: 15px 25px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 802px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}
div >>> .vs--searchable .vs__dropdown-toggle {
  cursor: text;
  width: 200px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 0px 8px rgb(255 255 255 / 20%);
  height: 48px;
  padding: 0 34px 0 34px;
  line-height: 47px;
  font-weight: 400;
  color: #000;
  font-family: "Lato", sans-serif;
  background: white;
  word-wrap: normal;
  margin-right: 0px !important;
  margin-left: 15px;
}

.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
  height: 4.5em;
  content: counter(step);
  counter-increment: step;
  line-height: 4em;
  border: 6px solid green;
  display: block;
  text-align: center;
  margin: 10px auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}
.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}

div >>> .homepage-5 .btn-yellow:hover {
  background: #548f4d !important;
  background-color: #548f4d !important;
}

div >>> .multiselect__placeholder {
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__input {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__tags {
  margin-left: 15px;
  height: 50px;
  max-width: 202px;
  width: 202px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
  padding-top: 13px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0 8px rgb(255 255 255 / 20%);
  padding-left: 28px;
}

div >>> .multiselect__select:before {
  top: 85%;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__placeholder {
  padding-left: 4px;
}
</style>
